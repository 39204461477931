@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsLight.ttf");
  font-family: "Poppins";
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsBold.ttf");
  font-family: "Poppins";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlayfairDisplayRomanBlack.ttf");
  font-family: "Playfair Display";
  font-weight: 900;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/SatisfyRegular.ttf");
  font-family: "Satisfy";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsMedium.ttf");
  font-family: "Poppins";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlayfairDisplayRomanMedium.ttf");
  font-family: "Playfair Display";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsExtraBold.ttf");
  font-family: "Poppins";
  font-weight: 800;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/NunitoSansExtraBold.ttf");
  font-family: "Nunito Sans";
  font-weight: 800;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/NunitoSansSemiBold.ttf");
  font-family: "Nunito Sans";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlayfairDisplayRomanRegular.ttf");
  font-family: "Playfair Display";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/NunitoSansBold.ttf");
  font-family: "Nunito Sans";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/NunitoMedium.ttf");
  font-family: "Nunito";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/NunitoSansRegular.ttf");
  font-family: "Nunito Sans";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterBold.ttf");
  font-family: "Inter";
  font-weight: 700;
}
