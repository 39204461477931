@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.container {
    max-width: 1600px;
}
.our_service {
    .container {
        max-width: 1920px;
    }
}
.ser_bottom_img {
    &.container {
        max-width: 1600px;
    }
}
.aboutus_box  {
    .container {
        max-width: 1300px;
    }
}
.container {
    &.mx-1000 {
        max-width: 1000px;
    }
    &.mx-1100 {
        max-width: 1100px;
    }
    &.mx-1200 {
        max-width: 1200px;
    }
    &.mw_1300 {
        max-width: 1300px;
    }
    &.mw_1400 {
        max-width: 1400px;
    }
    &.mw_1920 {
        max-width: 1920px;
    }
}
/*Header*/
.header-section {
    padding: 1rem 0;
}
.headerlogo {
    width: 120px;
}
.header_search {
    box-shadow: 0px 4px 6px 0px #1B5D8240 !important;
    border-radius: 40px;
    padding: 0.25rem 2rem;
    input {
        border: none !important;
        outline: none !important;
        background: transparent !important;
        padding: 0 !important;
        box-shadow: none !important;
        font-weight: 300;
        &::placeholder {
            color: #83A2B4;
            opacity: 1;
        }
        &::-ms-input-placeholder {
            color: #83A2B4;
        }
        &::-moz-input-placeholder {
            color: #83A2B4;
            opacity: 1;
        }
        &::-webkit-input-placeholder {
            color: #83A2B4;
        }
    }
}
.header_socialIcon {
    a {
        display: inline-flex;
        margin: 0 0.8rem;
        img {
            height: 24px;
            width: 24px;
        }
    }
}
/*Banner*/
.color_3A3F50 {
    color: #3A3F50;
}
.font11 {
    font-size: 11rem;
}
.font10 {
    font-size: 8rem;
}
.font6 {
    font-size: 5.3rem;
}
.font5 {
    font-size: 5rem;
}
.font4 {
    font-size: 4rem;
}
.font3 {
    font-size: 3rem;
}
.font2 {
    font-size: 2rem;
}
.font2_5 {
    font-size: 2.5rem;
}
.font2_25 {
    font-size: 2.25rem;
}
.font15_5 {
    font-size: 1.5rem;
}
.font1_5 {
    font-size: 1.25rem;
}
.font1 {
    font-size: 1rem;
}
.bannerP {
    font-size: 1.25rem;
    color: #000;
    max-width: 85%;
    line-height: 35px;
}
.plane {
    height: 80px;
    width: 375px;
    .vector_2 {
        top: 45px;
    }
    .vector_1 {
        width: 45px;
    }
}
.helicopter {
    width: 40%;
    top: -120px;
    left: 55px;
}
.pos-cen {
    background-position: center right;
}
.jetbox {
    width: 60px;
    height: 60px;
    border: 2px solid #1B5D82;
    border-radius: 50%;
    padding: 2px;
}
/*Menu*/
.main_menu {
    ul {
        font-weight: 500;
        color: #3A504D;
        line-height: 38px;
        li {
            font-size: 1.4rem;
            padding: 0 1.4rem;
            a {
                color: #3A504D;
            }
        }
    }
}
.home_nav {
    ul {
        li {
            a:hover {
                text-decoration: underline;
            }
        }
    }
}
.inner_menu {
    .main_menu {
        ul {
            li {
                font-size: 1rem;
                padding: 0 0.9rem;
            }
            a.activeColor {
                color: #319185;
            }
        }
    }
}
/*pages*/
.choose_destination {
    padding: 15rem 0 13rem;
}
.bg_tower {
    background-image: url("../images/bg_tower.png");
}
.shades {
    box-shadow: 0px 4px 5.2px 0px #1B5D8266;
    margin-top: 5rem;
}
.desti_slider {
    width: 33.333%;
}
.nest {
    width: 300px;
    right: 0;
}
.service_bottom {
    z-index: 10;
    bottom: 0;
    right: 0px;
}
.user-profile {
    text-align: center;
    margin: 12px 12px 0;
}
.user-image {
    width: 230px;
    height: 350px;
    //object-fit: cover;
    //transition: transform 0.3s ease-in-out;
}
.user-profile:hover .user-image {
    //transform: scale(1);
}
.migrate  {
    padding-top: 3rem;
    padding-bottom: 0rem;
    background-position: top center;
}
.migP {
    font-size: 1.25rem;
    max-width: 45%;
    padding: 1rem 0 4rem;
}
.mh_600 {
    max-width: 1400px;
    margin: auto;
    min-height: 600px;
}
.coaching {
    background: #F9FFFE;
    .mov_top {
        padding-top: 6rem;
        z-index: 10;
    }
    .topNest{
        width: 250px;
        left: -10px;
        top: 0;
    }
    .bottomNest{
        width: 250px;
        left: -10px;
        bottom: 0;
    }
    .bottomLine {
        width: 50%;
        right: 25%;
        bottom: -120px;
    }
    .rigBG {
        height: 600px;
        position: absolute;
        top: 15%;
    }
    .beBG {
        width: 80% !important;
        position: relative;
        z-index: 1;
        margin-left: 10%;
    }
}
.aboutus_box_white {
    padding: 7rem 1rem 15rem;
    max-width: 960px;
    margin: 0px auto;
}
.pos_bottom {
    background-position: bottom center;
}
.about_bottom {
    bottom: -3px;
}
.about_top {
    right: 0px;
}
.resources {
    padding: 5rem  0 5rem;
    &.abt_res {
        padding-bottom: 7rem;
    }
    .top_img {
        width: 250px;
        left: -20px;
        top: -70px;
        &.right__20 {
            left: auto;
            right: 20px;
        }
    }
    .cen_img {
        width: 450px;
        margin: 0px auto;
    }
    .bot_img {
        width: 300px;
        right: 80px;
        bottom: -50px;
        &.left80 {
            left: 80px;
        }
    }
    .bottomLines {
        width: 450px;
        right: 200px;
        top: -50px;
    }
    .bottomPlane {
        width: 300px;
        right: 0px;
        bottom: -70px;
        &.bot__110 {
            bottom: -110px;
        }
    }
    .cen_img1 {
        width: 380px;
        margin: 0px auto;
    }
    .left_bar {
        width: 15px;
        height: 545px;
        top: 8rem;
    }
}
.google_icon {
    padding: 2rem 0;
    .swip_img {
        max-width: 1300px;
        display: flex;
        justify-content: space-between;
        margin: 0px auto;
        img {
            width: 130px;
            margin: 15px 0;
        }
    }
}
.testimonial {
    padding: 7rem 0 3rem;
    .test_title {
        line-height: 100px;
        margin-bottom: 4rem;
    }
    .left_tele {
        left: -6px;
    }
    .right_tele {
        right: -6px;
    }
    .left_comma {
        top: -20px;
        left: 140px;
    }
    .right_comma {
        bottom: -20px;
        right: 145px;
    }
    .pt-25r {
        padding-top: 2.5rem;
    }
}
.contactus_box {
    .con_image {
        width: 85%;
    }
    .lefBG {
        position: absolute;
        height: 75%;
        top: 13%;
        width: 95%;
    }
    .con_forms {
        width: 80%;
    }
    .con_label {
        box-shadow: 0px 4px 6.8px 0px #1B5D8229 !important;
        margin-bottom: 15px;
        color: #1B5D82;
        input {
            border: none !important;
            outline: none !important;
            background: transparent !important;
            padding: 0  3px !important;
            box-shadow: none !important;
            font-weight: 600;
            font-family: "Nunito Sans";
            color: #1B5D82;
            font-size: 1.25rem;
            &::placeholder {
                color: #1B5D82;
                opacity: 1;
            }
            &::-ms-input-placeholder {
                color:#1B5D82;
            }
            &::-moz-input-placeholder {
                color: #1B5D82;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #1B5D82;
            }
        }
    }
    textarea.con_label {
        border: none !important;
        outline: none !important;
        background: transparent !important;
        padding: 10px 15px !important;
        box-shadow: 0px 4px 6.8px 0px #1B5D8229 !important;
        font-weight: 600;
        font-family: "Nunito Sans";
        color: #1B5D82;
        font-size: 1.25rem;
        &::placeholder {
            color: #1B5D82;
            opacity: 1;
        }
        &::-ms-input-placeholder {
            color:#1B5D82;
        }
        &::-moz-input-placeholder {
            color: #1B5D82;
            opacity: 1;
        }
        &::-webkit-input-placeholder {
            color: #1B5D82;
        }
    }
}
/*submitBtn*/
.submitBtn {
    border: 1px solid #1B5D82;
    span {
        background: #1B5D82;
        color: #fff;
        padding: 12px 35px;
        width: 100%;
        display: inline-flex;
        position: relative;
        left: -5px;
        top: -5px;
        font-size: 1.5rem;
        line-height: 26px;
        font-family: "Nunito Sans";
        font-weight: 600;
        &.advice {
            padding: 12px 50px;
        }
    }
}
/*ScrollTop*/
.scroll-index {
    right: 0.3rem;
    top: 5rem;
    z-index: 100;
}
.scroll-top {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
/*footer*/
.contact_detail {
    position: relative;
    bottom: -4.5rem;
    .container {
        max-width: 1300px;
    }
    .contact_detail_bg {
        background-color: #1B5D82;
    }
    .con-box {
        width: 75px;
        height: 75px;
        img {
            height: 30px;
        }
    }
    .hea_tits {
        line-height: 20px;
        margin-bottom: 6px;
        font-size: 1.15rem;
    }
}
.line-height26 {
    line-height: 26px;
}
.footer_bg {
    padding-top: 5rem;
    background-color: #082A3ECF;
    .w-140px {
        width: 140px;
    }
    .footer-link {
        font-size: 1.2rem;
        li {
            padding: 0 20px;
        }
    } 
    .pr-20px {
        padding-right: 20px;
    }
    .social_icon {
        img {
            width: 40px;
            height: 40px;
            margin: 0 15px;
        }
        a:first-child img {
            margin-left: 0;
        }
    }
}
.foot_bot {
    .footer_bg {
        padding-top: 3rem;
    }
}
/*inner pages*/
.banner_inner {
    height: 100vh;
    .inner_bg {
        height: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.trans {
            background: #000000A6;
        }
    }
}
.why_migrate {
    padding: 5rem 0 3rem;
}
.bg_F8FEFD {
    background-color: #F8FEFD;
}
.bg_DFECEC91 {
    background: #DFECEC91;
}
.bg_bottom {
    background-position: bottom;
}
.desti_pad {
    padding: 7rem 0 4rem;
}
.study_destin {
    padding: 5rem 0 3rem;
}
.contact_col {
    padding: 13rem 0 4rem;
    &.about_cols {
        padding: 6rem 0 6rem;
        margin-bottom: -40px;
    }
    &.mig_cols {
        padding: 5rem 0 1rem;
    }
    &.mb__70 {
        margin-bottom: -70px;
    }
    &.res_cols {
        padding-top: 8rem;
    }
    &.soft_cols {
        padding: 5rem 0 3rem;
    }
}
.pa10 {
    padding: 5rem 0 5rem;
}
.soft_box_1 {
    padding: 7rem 0 3rem;
}
.soft_box_2 {
    padding: 4rem 0 4rem;
}
.soft_box_3 {
    padding: 4rem 0 4rem;
}
.mb_70px {
    margin-bottom: -70px;
}
.py-tb2{
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.tab_con {
    img  {
        width: 80%;
    }
}
.black_img {
    background-image: url("../images/content_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    position: relative;
    z-index: 100;
    top: -70px;
    &.top__20 {
        top: -40px;
    }
}
.bg_black {
    background-color: #000;
}
.cont_text {
    max-width: 860px;
    margin: 1rem auto 5rem;
    font-size: 1.4rem;
}
.color_424242 {
    color: #424242;
}
.borer {
    border-top: 0.5px solid #1B5D8280
}
.borerLef {
    &:after {
        content: " ";
        display: inline-flex;
        background-color: #1B5D8280;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0px;
        right: -30px;
    }
}
.plane_icons {
    width: 100px !important;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
.left__8 {
    left: -8px;
}
.top__100 {
    top: -100px;
}
.line_before {
    position: relative;
    &:before {
        content: "";
        height: 3px;
        display: inline-block;
        background-color: #1b5c82;
        width: 75px;
        margin-left: 5px;
        margin-right: 10px;
    }
}
.con_img {
    width: 80%;
}
.form_part {
    max-width: 900px;
    margin: 2rem auto;
}
.abt_part {
    max-width: 700px;
    margin: 2rem auto;
}
.car_part {
    max-width: 700px;
    margin: 1rem auto 0;
}
.mig_part {
    max-width: 900px;
    margin: 2rem auto;
}
.arrows {
    width: 35px;
    height: 35px;
    margin: 0 5px !important;
}
.box_col_shade {
    box-shadow: 0px 1px 6.5px 0px #1B5D8233;
    margin: 0.5rem 0;
}
.m_1200 {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.call_icon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 5px;
    img {
        height: 45px;
    }
}
.bg_06090A {
    background-color: #06090A;
}
.w-90per {
    width: 90%;
}
.w-85per {
    width: 85%;
}
.ml-80per {
    margin-left: 8%;
}
.t50 {
    top: -50px;
}
.w-40px {
    width: 25px;
}
.mb__70 {
    margin-bottom: -70px;
}
.mission_img {
    padding: 8rem 2rem;
    width: 95%;
}
.count_img {
    border-radius: 10px;
    width: 95%;
}
.soft_line {
    height: 3px;
    display: inline-flex;
    width: 150px;
    background: #fff;
    position: absolute;
    left: -125px;
    top: 15px;
    &.lef-90 {
        left: -90px;
    }
}
.seminar_video {
    padding: 3rem 0 0rem;
}
.video_play {
    width: 250px;
}
.min_wi220 {
    min-width: 220px;
    text-align: center;
}
.faq_part {
    margin-left: 25px;
    .accordion-item {
        border-radius: 0 !important;
        margin-bottom: 5px;
        box-shadow: 0px 2px 4.5px 0px rgba(27, 93, 130, 0.21);
        border: 0;
        color: #000;
        font-size: 0.95rem;
        .accordion-button {
            box-shadow: none !important;
            padding: 14px 10px 14px 40px;
            font-size: 0.9rem;
            background-color: transparent;
            color: inherit;
            &::before {
                content: " ";
                background-image: url(../images/faq_plus.png);
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                background-size: 100%;
                position: absolute;
                left: 10px;
            }
            &::after {
                content: inherit;
            }
        }
        .accordion-body {
            padding: 10px 15px;
            font-size: 0.95rem;
            color: #000;
        }
    }
}
.mob_student {
    .font2 {
        font-size: 1.75rem;
    }
    .font1_5 {
        font-size: 1.15rem;
    } 
    .leading-\[30px\] {
        line-height: 28px;
    }
}
.form_design {
    .con_label {
        box-shadow: 0px 4px 6.8px 0px #1B5D8229 !important;
        margin-bottom: 25px;
        color: #1B5D82;
        input {
            border: none !important;
            outline: none !important;
            background: transparent !important;
            padding: 0  3px !important;
            box-shadow: none !important;
            font-weight: 600;
            font-family: "Nunito Sans";
            color: #1B5D82;
            font-size: 1.25rem;
            &::placeholder {
                color: #1B5D82;
                opacity: 1;
            }
            &::-ms-input-placeholder {
                color:#1B5D82;
            }
            &::-moz-input-placeholder {
                color: #1B5D82;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #1B5D82;
            }
        }
    }
    textarea.con_label {
        border: none !important;
        outline: none !important;
        background: #fff !important;
        padding: 10px 15px !important;
        box-shadow: 0px 4px 6.8px 0px #1B5D8229 !important;
        font-weight: 600;
        font-family: "Nunito Sans";
        color: #1B5D82;
        font-size: 1.25rem;
        height: 130px;
        &::placeholder {
            color: #1B5D82;
            opacity: 1;
        }
        &::-ms-input-placeholder {
            color:#1B5D82;
        }
        &::-moz-input-placeholder {
            color: #1B5D82;
            opacity: 1;
        }
        &::-webkit-input-placeholder {
            color: #1B5D82;
        }
    }
}