body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

a {
  text-decoration: none;
  display: block;
}

ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}

input {
  background-color: transparent;
  width: 100%;
}

input:focus {
  outline: none;
  border: none;
}
.tab-panel--selected {
  animation-name: tabPanel;
  animation-duration: 300ms;
  animation-timing-function: linear;
}
@keyframes tabPanel {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.alice-carousel__dots-item {
  cursor: pointer;
}
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
  }
  .social-icons {
    justify-content: space-evenly;
  }
  .img-container {
    width: 100%;
  }
  .text-container {
    text-align: center;
    padding: 10px;
  }
}