@media (max-width: 1540px) and (min-width: 1281px) {
    .font11 {
        font-size: 9rem;
    }
    .font10 {
        font-size: 7rem;
    }
    .font6 {
        font-size: 4.8rem;
    }
    .font5 {
        font-size: 4.3rem;
    }
    .font4 {
        font-size: 3.8rem;
    }
    .font3 {
        font-size: 2.8rem;
    }
    .font2 {
        font-size: 1.6rem;
    }
    .font2_5 {
        font-size: 2.25rem;
    }
    .font2_25 {
        font-size: 2rem;
    }
    .font15_5 {
        font-size: 1.25rem;
    }
    .font1_5 {
        font-size: 1rem;
    }
    .font1 {
        font-size: 0.9rem;
    }
    .bannerP {
        font-size: 1rem;
        max-width: 90%;
    }
}
@media (max-width: 1280px) and (min-width: 992px) {
    .font6 {
        font-size: 4.5rem;
    }
    .font5 {
        font-size: 3.5rem;
    }
    .font4 {
        font-size: 2.5rem;
    }
    .font3 {
        font-size: 1.5rem;
    }
    .font10 {
        font-size: 7rem;
    }
    .bannerP {
        font-size: 1rem;
        max-width: 100%;
    }
    .helicopter {
        width: 35%;
        top: -50px;
        left: 30px;
    }
    .main_menu {
        ul {
            li {
                font-size: 1rem;
                padding: 0 1rem;
            }
        }
    }
    .inner_menu {
        .main_menu {
            ul {
                li {
                    font-size: 0.9rem;
                    padding: 0 0.75rem;
                }
            }
        }
    }
    .user-image {
        height: auto;
    }
    .testimonial {
        .right_tele, .left_tele {
            display: none;
        }
    }
    .left_bar {
        display: none;
    }
    .footer_bg {
        .footer-link {
            font-size: 1.1rem;
            li {
                padding: 0 10px;
            }
        } 
        .social_icon img {
            width: 30px;
            height: 30px;
        }
    }
}
@media (max-width: 991px) {
    .m-none {
        display: none !important;
    }
    .font10 {
        font-size: 3rem;
    }
    .font6 {
        font-size: 2.2rem;
    }
    .font5 {
        font-size: 2.4rem;
    }
    .font4, .font3 {
        font-size: 2.15rem;
    }
    .submitBtn {
        border: 0;
        span {
            padding: 8px 22px;
            left: 0;
            top: 0;
            font-size: 0.9rem;
            line-height: 26px;
            font-weight: 700;
        }
    }
    .drawer-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        z-index: 105;
        background-color: rgba(0, 0, 0, 0.2);
        display: none;
    }
    .drawer-overlay.active {
        display: block;
        animation: animation-drawer-fade-in 0.3s ease-in-out 1;
    }
    @keyframes animation-drawer-fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .menu-icon {
        cursor: pointer;
    }
    .nav-elements {
        position: fixed;
        left: 0;
        top: 0;
        background-color: #fff;
        display: none;
        height: 100vh;
        transition: all 0.3s ease-in;
        z-index: 110;
    }
    .nav-elements.active {
        width: 270px;
        display: block;
        animation: leftToRight 0.5s ease-in-out forwards;
    }
    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }
    .header-section {
        padding: 0.5rem 0;
    }
    .headerlogo {
        width: 65px;
    }
    .menu_close {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #000;
        color: #000;
        font-weight: 600;
        font-size: 18px;
        cursor: pointer;
    }
    .main_menu {
        ul {
            li {
                font-size: 1.2rem;
                padding: 0.4rem 1.5rem;
            }
            a{
                &:hover, &.activeColor {
                    color: #1B8282;
                }
            }
        }
    }
    .inner_menu {
        .main_menu {
            ul {
                li {
                    font-size: 1.2rem;
                    padding: 0.4rem 1.5rem;
                }
            }
        }
    }
    .w-18px {
        width: 18px;
    }
    .banner {
        background-image: url("../images/banner_bg.png");
        text-align: center;
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 3rem 0 6rem;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: radial-gradient(112.68% 112.68% at 51.21% 0%, rgba(31, 58, 62, 0.92) 0%, rgba(46, 86, 92, 0.92) 99.99%, rgba(82, 154, 164, 0.92) 100%);
        }
        .color_3A3F50, .text-blue_gray-800, .bannerP {
            color: #fff;
        }
        .text-light_blue-900 {
            color: #A2D6F4;
        }
        .submitBtn {
            span {
                background: #fff;
                color: #1B5D82;
            }
        }
        .bannerP {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .bannerP {
        font-size: 1rem;
        max-width: 95%;
        line-height: 30px;
        margin-left: auto;
        margin-right: auto;
    }
    .helicopter, .plane, .scroll-index {
        display: none;
    }
    .jetbox {
        top: -90px;
        right: 10px;
        position: absolute;
    }
    .chat-modal {
        max-width: 300px !important;
        transform: translateX(0%) !important;
        margin-left: 0 !important;
    }
    .choose_destination {
        padding: 0 0 6rem;
        text-align: center;
        .shades {
            animation: none;
            width: 100%;
            text-align: left;
            padding: 1.5rem;
            margin-top: 0rem;
        }
        a.learn  {
            position: absolute;
            bottom: 30px;
            left: 50%;
            margin-left: -55px;
        }
        .desti_slider {
            width: 30%;
        }
        .bannerP {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .ser_bottom_img.container {
        display: none;
    }
    .service_bottom {
        position: relative;
    }
    .ml-80 {
        margin-left: 5rem;
        width: 140px;
    }
    .our_service {
        .bannerP {
            max-width: 100%;
        }
    }
    .migrate {
        padding-bottom: 3rem;
        .migP {
            font-size: 0.95rem;
            max-width: 90%;
            padding: 1rem 0 2rem;
        }
        .mh_600 {
            min-height: auto;
            justify-content: center !important;
            div.transform {
                display: none;
                &:first-child {
                    display: block;
                }
            }
            img  {
                width: 200px;
                height: auto;
                margin: 0px auto 20px;
            }
        }
    }
    .coaching {
        background-color: #fff;
        .mov_top {
            padding-top: 0;
            margin-left: 0;
            text-align: center;
        }
        .mlr--12 {
            margin-left: -12px;
            margin-right: -12px;
        }
        .coach_star {
            position: absolute;
            top: 2.5rem;
            width: 35px;
            left: 15px;
        }
        .rigBG {
            height: 125px;
            bottom: 0;
            top: auto;
        }
        .beBG {
            width: 270px !important;
            margin-left: 0;
            margin: 20px auto 0px;
        }
        .bannerP {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
    .aboutus_box {
        .container {
            padding: 0;
        }
        .pos_bottom {
            background-color: #F9FFFE !important;
        }
        .aboutus_box_white {
            padding: 1.5rem 1rem 3rem;
        }
        .abt_sub {
            font-size: 0.95rem !important;
            padding: 1rem 0;
        }
    }
    .resources {
        text-align: center;
        padding: 2rem 0;
        .mw-200 {
            max-width: 200px;
            margin: 2rem auto 0;
        }
        .cen_img {
            width: 100%;
        }
        .bot_img {
            width: 135px;
            right: -40px;
            bottom: -35px;
        }
        .top_img {
            width: 130px;
            left: -125px;
            top: -65px;
        }
        .pt2r {
            padding-top: 2rem;
        }
        .bottomLines {
            width: 180px;
            right: 0px;
            top: -20px;
        }
    }
    .google_icon {
        padding: 1rem 0 1rem;
        .swip_img {
            flex-wrap: wrap;
            img {
                width: 70px;
                margin: 10px;
                &:nth-child(1), &:nth-child(2), &:nth-child(6) {
                    display: none;
                }
            }
        }
    }
    .testimonial {
        padding: 3rem 0 2rem;
        .test_title {
            line-height: 40px;
        }
        .pt-25r {
            border: 2px solid #fff;
            border-radius: 10px;
            padding: 1rem !important;
        }
        .bg-white-a700_01 {
            border-radius: 10px;
        }
        button.bg-white-a700_01  {
            border-radius: 50%;
        }
        .left_tele {
            display: none;
        }
        .right_tele {
            display: none;
        }
        .left_comma {
            top: -10px;
            left: 50px;
            width: 20px;
        }
        .right_comma {
            bottom: -4px;
            right: 50px;
            width: 20px;
        }
        .pos-rels {
            position: relative;
        }
        .arr_btn {
            margin-top: 2rem;
        }
    }
    .contactus_box {
        text-align: center;
        margin-bottom: 1.5rem;
        .mlr--12 {
            margin-left: -12px;
            margin-right: -12px;
        }
        .lefBG {
            position: absolute;
            width: 100%;
            height: 125px;
            bottom: 0;
            top: auto;
        }
        .con_image {
            width: 85%;
            margin: 0px auto 2rem;
        }
        .con_forms {
            width: 100%;
        }
        .img_m_10px {
            margin-bottom: 10px;
            &:nth-child(2), &:nth-child(3) {
                display: none;
            }
        }
    }
    .padd0 {
        padding: 0;
    }
    .contact_detail {
        bottom: 0px;
        .container {
            padding: 0;
        }
        .contact_detail_bg {
            padding: 1.25rem 1.5rem;
        }
        .mov_lefts {
            justify-content: start !important;
            align-items: center;
            margin: 7px 0;
            font-size: 0.95rem;
            .in-block {
                display: inline-block !important;
            }
        }
        .con-box {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 1.25rem !important;
            img {
                height: 20px;
            }
        }
    }
    footer {
        background-image: url("../images/footer_bg.png") !important;
    }
    .footer_bg {
        padding-top: 2rem;
        background-color: transparent;
        .footer-link {
            font-size: 1rem;
            li {
                padding: 0 0 15px 5px;
            }
        }
        .social_icon {
            position: relative;
            top: -60px;
            justify-content: center !important;
            img {
                width: 20px;
                height: 20px;
                margin: 0 10px;
            }
            a:first-child img {
                margin-left: 10px;
            }
        }
        .w-140px {
            width: 90px;
            margin: 0px auto;
            position: relative;
            top: -30px;
        }
        .mob-size {
            font-size: 9px !important;
            margin-top: 1rem;
        }
        .pr-20px {
            padding-right: 10px;
        }
    }
    /*inner pages*/
    .banner_inner {
        height: 350px;
        background-position: center;
        .font6 {
            font-size: 2rem;
        }
        .inner_bg {
            padding: 0 20px;
        }
    }
    .study_destin {
        padding: 2rem 0 2rem;
        .font4 {
            font-size: 2rem;
        }
        .migP {
            font-size: 0.9rem;
            max-width: 100%;
            padding: 1rem 0 3rem;
        }
    }
    .study_banner {
        background-image: url("../images/study_banner.png");
    }
    .animate-zoomInOut p {
        padding: 0 15px;
    }
    .pa10 {
        padding: 2rem 0 2rem;
        .font2 {
            font-size: 1.25rem;
        }
    }
    .ml-80per {
        margin-left: 5%;
    }
    .soft_box_1 {
        padding: 1rem 0 1rem;
        .w-85per {
            width: 90%;
        }
        img {
            max-width: 80%;
            margin: 2rem auto;
        }
        .ml-80per {
            img {

                margin: 5px auto;
                height: auto;
            }
        }
        .font3 {
            font-size: 1.5rem;
        }
    }
    .mob_migs {
        width: 33px;
        height: 33px;
        max-width: 33px;
        min-width: 33px;
    }
    .soft_box_2 {
        padding: 2rem 0 1rem;
        .w-85per {
            width: 90%;
        }
        img {
            max-width: 80%;
            margin: 2rem auto;
        }
        .font3 {
            font-size: 1.5rem;
        }
    }
    .soft_box_3 {
        padding: 2rem 0 1rem;
        .w-85per {
            width: 90%;
        }
        img {
            max-width: 80%;
            margin: 2rem auto;
        }
        .font3 {
            font-size: 1.5rem;
        }
    }
    .top__100 {
        top: -50px;
    }
    .font11 {
        font-size: 5rem;
    }
    .mission_img {
        padding: 2rem;
        width: 100%;
    }
    .mp-0 {
        margin-top: 2rem;
        padding: 0;
        .font3 {
            font-size: 1.6rem;
        }
    }
    .pal-0 {
        padding-right: 0;
        padding-left: 0;
    }
    .news_section {
        .w-90per {
            width: 100%;
        }
    }
    .call_icon {
        width: 80px;
        height: 80px;
        img {
            height: 35px;
        }
    }
    .t50 {
        top: -40px;
        max-width: 85%;
    }
    .seminar_video {
        padding: 0rem 0 1rem;
        .min_wi220 {
            min-width: inherit;
            text-align: center;
            margin: 10px 5px !important;
            padding: 10px 13px;
        }
        .mt-8 {
            margin-top: 0rem !important;
        }
        ul {
            margin-bottom: 1rem;
        }
    }
    .mt-xxx {
        img {
            margin-top: 15px;
        }
    }
    .cont_text {
        font-size: 0.85rem;
        padding: 0 15px;
    }
    .form_part, .form_design {
        margin-top: 1rem !important;
    }
    .con_bot_img {
        margin-top: 0;
        .mbs {
            margin-bottom: 10px;
            &:nth-child(2), &:nth-child(3) {
                display: none;
            }
        }
    }
    .sbs {
        &:nth-child(3), &:nth-child(4) {
            display: none;
        }
    }
    .font2_5 {
        font-size: 1.5rem;
    }
    .video_play {
        width: 100px;
    }
    .video_para {
        line-height: inherit;
    }
    .contact_col {
        padding: 3rem 0 2rem;
        &.about_cols {
            padding: 4rem 0 4rem;
            margin-bottom: 0;
        }
        &.res_cols {
            padding-top: 2rem;
        }
        &.mb__70 {
            margin-bottom: 0;
        }
        &.mig_cols {
            padding: 2rem 0 1rem;
        }
    }
    .count_btn {
        button {
            width: auto !important;
            padding: 10px 8px !important;
            margin: 5px 2px !important;
            font-size: 0.9rem;
        }
    }
    .count_img {
        margin: 0px auto;
    }
    .mob_center {
        text-align: center;
    }
    .mig_part {
        .font3 {
            font-size: 1.75rem;
        }
    }
    .resources {
        &.abt_res {
            padding-bottom: 0rem;
        }
        &.mig_res {
            padding-top: 0;
        }
    }
    .english_coa {
        text-align: left;
        padding-bottom: 4rem;
        .font3 {
            font-size: 1.7rem;
        }
        .mb_70px {
            margin-bottom: 0;
        }
    }
    .banner_inner {
        .inner_bg {
            background: rgba(18, 9, 9, 0.8);
            &.trans {
                background: rgba(18, 9, 9, 0.8);
            }
        }
    }
    .w-75per {
        max-width: 75%;
        margin: 0px auto;
    }
    .tab_parts {
        ul li {
            font-size: 0.9rem;
            padding: 10px;
            margin: 5px 5px !important;
            width: 30%;
            text-align: center;
        }
    }
    .tab_con {
        img {
            width: 100%;
        }
    }
    .mb_70px {
        margin-bottom: 0;
    }
    .box_column {
        text-align: left;
        margin-top: 2.5rem !important;
        .col-6 {
            padding: 0 5px;
        }
        .box_col_shade {
            margin: 0.35rem 0;
            padding: 10px !important;
            min-height: 65px;
        }
        img {
            height: 25px;
        }
    }
    .black_img {
        top: 0;
        background: #06090A;
        &.top__20 {
            top: 0;
        }
    }
    .about_banner {
        background-image: url("../images/software_banner.png");
    }
    .software_banner {
        background-image: url("../images/software_banner.png");
    }
    .migrate_banner {
        background-image: url("../images/migrate_banner.png");
    }
    .english_banner {
        background-image: url("../images/english_banner.png");
    }
    .resource_banner {
        background-image: url("../images/resource_banner.png");
    }
    .contact_banner {
        background-image: url("../images/contact_banner.png");
    }
    .choose_bg {
        background-image: url("../images/choose_bg.png");
        margin: 0px -12px;
        background-size: 100%;
        background-repeat: no-repeat;
        position: relative;
        min-height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        &:before {
            content: "";
            display: inline-flex;
            background: #081B2582;
            position: absolute;
            top: 0px;
            width: 100%;
            height: 100%;
        }
        h3 {
            font-size: 1rem;
            color: #fff;
            position: relative;
            margin: 0;
            width: 100%;
            text-align: center;
        }
    }
    .soft_line {
        height: 30px;
        display: inline-flex;
        width: 3px;
        background: transparent;
        position: static;
        border: 2px dotted #fff;
    }
    .desti_pad {
        padding: 0 0 1rem;
    }
    .font1_5 {
        font-size: 0.90rem;
    }
    .font15_5 {
        font-size: 0.95rem;
    }
    .font2 {
        font-size: 1rem;
    }
    .font1 {
        font-size: 0.85rem;
    }    
    .font3 {
        font-size: 2rem;
    }
    .why_migrate {
        padding: 1rem 0 0;
        .w-90per {
            width: 100%;
        }
    }
    .Arrowleft {
        height: 15px;
    }
    .font0_75 {
        font-size: 0.75rem;
    }
    .soft_cols.pb_00 {
        padding-bottom: 0;
    }
    .mob_student {
        background-image: url("../images/img_group_469_1.png");
        margin: 0px -12px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 12px;
        h3, p {
            color: #fff;
        }
        img {
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
            margin-top: 0;
        }
        .font2 {
            font-size: 1rem;
        }
        .font1_5 {
            font-size: 0.9rem;
        }
    }
    .form_design .con_label {
        margin-bottom: 10px;
    }
}
